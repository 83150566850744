<template>
  <div class="payment-link-page campaign-list-page">
    <div class="page-header page-filter">
      <div class="container-fluid">
        <div class="row clearfix">
          <div class="col-12">
            <div class="d-flex justify-content-between align-items-center">
              <!-- <div class="left-label"><h5><i class="fa fa-arrow-circle-left text-apple-green"></i> Payment</h5></div>
                <img src="@/assets/img/logo-powered-by.png" alt="PayAngel Logo" class="img-fluid" width="175"> -->
              <div class="left-label" v-if="showDiv">
                <h5>
                  <!-- <i class="fa fa-arrow-circle-left text-apple-green"></i> -->
                  PayAngel Checkout 
                </h5>
              </div>
              <!-- <h6 class="mb-0">
                Already have an account?
                <router-link to="" class="text-apple-green">Log in</router-link> -->
                <!-- to="/authentication/page-login" -->
              <!-- </h6> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="page-content mt-4">
      <div class="container-fluid">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GenericPaymentLayout",
  data(){
    return {
      showDiv : true
    }
  },
  mounted(){
    if(this.$route.name == 'success' || this.$route.name == 'failure' || this.$route.name == '403' || this.$route.name == 'session-expired'){
      this.showDiv = false;
    }
    // console.log(this.$route.name);
  },
  // computed:{
  //   routeName(){
  //     return this.$route.name
  //   },
  // }
};
</script>